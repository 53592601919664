import { qs } from '../dom-helpers';

class Header {
  constructor() {
    this.hamburger = qs('.hamburger');
    this.navMenu = qs('.header__nav');
    this.navMenuList = qs('.header__menu');
    this.actionsMenu = qs('.header__actions');
    this.header = qs('.header');
    this.links = this.navMenuList.querySelectorAll('.header__menu-link');
    this.langLinks = qs('.lang-menu').querySelectorAll('.lang-link');
    this._run();
  }

  _run() {
    try {
      this._registerEventListeners();
      this._setActiveMenuItem();
      this._handleLanguageSwitch();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerEventListeners() {
    if (this.hamburger) {
      this.hamburger.addEventListener('click', () => this._handleHamburgerMenu());
    }

    document.addEventListener('click', (event) => this._handleDocumentClick(event));

    this.langLinks.forEach((link) => {
      link.addEventListener('click', (event) => this._handleLanguageClick(event));
    });
  }

  _handleHamburgerMenu() {
    this.hamburger.classList.toggle('is-active');
    this.navMenu.classList.toggle('opened');
    this.actionsMenu.classList.toggle('opened');
  }

  _handleDocumentClick(event) {
    if (!event.target.closest('.hamburger') && !event.target.closest('.header__content')) {
      this.navMenu.classList.remove('opened');
      this.actionsMenu.classList.remove('opened');
      this.hamburger.classList.remove('is-active');
    }
  }

  _setActiveMenuItem() {
    const currentURL = window.location.href;
    this.links.forEach((link) => {
      if (link.href === currentURL) {
        const menuItem = link.parentElement;
        menuItem.classList.add('header__menu-item--is-active');
      } else {
        const menuItem = link.parentElement;
        menuItem.classList.remove('header__menu-item--is-active');
      }
    });
  }

  _handleLanguageSwitch() {
    const currentPath = window.location.pathname;
    const currentLang = currentPath.includes('/ru') ? 'ru' : 'en';

    this.langLinks.forEach((link) => {
      if (link.dataset.lang === currentLang) {
        link.parentElement.style.display = 'none';
      } else {
        link.parentElement.style.display = 'block';
      }
    });
  }

  _handleLanguageClick(event) {
    event.preventDefault();

    const selectedLang = event.target.dataset.lang;
    const currentPath = window.location.pathname;

    let newPath;

    if (selectedLang === 'ru') {
      newPath = currentPath === '/' || currentPath === '/index.html' ? '/ru/index.html' : `/ru${currentPath}`;
    } else {
      newPath = currentPath.replace('/ru', '');
      if (newPath === '' || newPath === '/') {
        newPath = '/index.html';
      }
    }

    const newUrl = new URL(newPath, window.location.origin).href;
    window.location.href = newUrl;
  }
}

export default Header;
